import React from 'react';
import appLogo from "../imgs/logo.webp";
import appStoreLogo from "../imgs/appstore-logo.webp"

const MainPage = () => {
    return (
        <div className="main-container">
            <div className="content-div">
                <img className="app-logo" src={appLogo} style={{width: "10vw"}} alt="App Logo"/>
                <div className="title-text title-header">
                    Chrono
                </div>
                <div className="title-text">
                    WATCH FACES GALLERY
                </div>
                <div className="description-div">
                    Download Chrono Watch Faces Gallery<br /> app from the App Store now!
                </div>
                <a href="https://apps.apple.com/pl/app/chrono-watch-faces-widgets/id6504392942">
                    <img className="appstore-logo" style={{width: "15vw"}} src={appStoreLogo} alt="Appstore Logo" />
                </a>
                <div className="links-div">
                    <a target="_blank" href="/terms">Terms of Use</a>
                    <a target="_blank" href="/privacy">Privacy Policy</a>
                </div>
                <div className="footer">
                    <span>Chrono Watch Faces Gallery</span>
                    <span>Email: support@chronowatch.co</span>
                    <span>All Rights Reserved 2024</span>
                </div>
            </div>
        </div>
    );
};

export default MainPage;