import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";

const App = () => {
    const location = useLocation();

    useEffect(() => {
        const pathMatch = /\/(privacy|terms)\/?$/.test(location.pathname);
        if (pathMatch) {
            document.body.classList.add("terms-privacy-body");
        } else {
            document.body.classList.remove("terms-privacy-body");
        }
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
        </Routes>
    );
};

export default App;
